<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-file" />&nbsp; Student Registration
          <div class="card-header-actions">
            <!-- <a
              href="https://coreui.io/vue/docs/components/card-components"
              class="card-header-action"
              rel="noreferrer noopener"
              target="_blank"
            > -->
            <small class="text-muted">Register</small>
            <!-- </a> -->
          </div>
        </CCardHeader>
        <CCardBody>
          <h3 v-if="!showForm">Welcome to Maha-Vidya</h3>
          <h5 v-if="!showForm">
            This Tablet is Registred by {{ form.student_name }}
          </h5>
          <CForm v-if="showForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.student }}</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.student_name"
                      id="classes"
                      placeholder=""
                    />
                    <span id="name_err" style="color: red"></span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="ipMobile"
                    class="col-sm-5 col-form-label"
                    style=""
                    >{{ labels.stu_contact }}</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.stu_contact"
                      id="ipMobile"
                      placeholder=""
                    />
                    <span id="contact_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.stu_email }}
                  </label>
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.stu_email"
                      id="classes"
                      placeholder=""
                    />
                    <span id="email_err" style="color: red"></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-5 col-form-label"
                    >{{ labels.adhar }} *</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.adhar_no"
                      id="classes"
                      placeholder="Student Adhar Card No."
                    />
                    <span id="adhar_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.dob }} *</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.dob"
                      id="classes"
                      placeholder=""
                    />
                    <span id="dob_err" style="color: red"></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.gender }} *</label
                  >
                  <div class="col-sm-6">
                    <b-form-group>
                      <b-form-radio-group v-model="form.gender">
                        <b-form-radio
                          v-model="form.gender"
                          name="some-radios"
                          value="male"
                          >Male</b-form-radio
                        >
                        <b-form-radio
                          v-model="form.gender"
                          name="some-radios"
                          value="female"
                          >Female</b-form-radio
                        >
                      </b-form-radio-group>
                      <span id="gender_err" style="color: red"></span>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-12 col-md-2 col-form-label"
                    >{{ labels.school }}*</label
                  >
                  <div class="col-sm-12 col-md-9">
                    <!-- <select class="form-control" v-model="form.school">
                      <option value="">Select School</option>
                      <option
                        v-for="(item, index) in school_list"
                        :key="index"
                        :value="item.school_id"
                      >
                        {{ item.school_name }}
                      </option>
                    </select> -->
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.school"
                      id="txtschool"
                      placeholder="School Name"
                    />
                    <span id="school_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.class }} *</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <select class="form-control" v-model="form.class">
                      <option value="">Select Class</option>
                      <option
                        v-for="(item, index) in class_items"
                        :key="index"
                        :value="item.class_id"
                      >
                        {{ item.class_standard }}
                      </option>
                    </select>
                    <span id="class_err" style="color: red"></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.division }} *</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <!-- <select class="form-control" v-model="form.division">
                      <option value="">Select Division</option>
                      <option
                        v-for="(item, index) in division_items"
                        :key="index"
                        :value="item.division_id"
                      >
                        {{ item.division }}
                      </option>
                    </select> -->
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.division"
                      id="txtschool"
                      placeholder="Division (A/B/C/D)"
                    />
                    <span id="division_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.parent_name }} *</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.parent_name"
                      id="classes"
                      placeholder=""
                    />
                    <span id="parent_name_err" style="color: red"></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-5 col-form-label"
                    >{{ labels.parent_contact }} *</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.parent_contact"
                      id="classes"
                      placeholder=""
                    />
                    <span id="parent_contact_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.parent_email }} *</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.parent_email"
                      id="classes"
                      placeholder=""
                    />
                    <span id="parent_email_err" style="color: red"></span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-12 col-md-2 col-form-label"
                    >{{ labels.address }}</label
                  >
                  <div class="col-sm-12 col-md-10">
                    <input
                      type="text"
                      class="form-control"
                      id="classes"
                      v-model="form.address"
                      placeholder="Enter Address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.city }}</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-model="form.city"
                      id="classes"
                      placeholder=""
                    /> -->
                    <select class="form-control" v-model="form.city">
                      <option value="">Select District</option>
                      <option
                        v-for="(item, index) in districts"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-5 col-md-4 col-form-label"
                    >{{ labels.pincode }}</label
                  >
                  <div class="col-sm-7 col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.pincode"
                      id="classes"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-4 col-form-label"
                    >{{ labels.registration_date }} *</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.registration_date"
                      id="classes"
                      placeholder=""
                    />
                    <span id="registration_date_err" style="color: red"></span>
                  </div>
                </div>
              </div> -->
            </div>
            <br />
            <!-- <div class="row">
                    <div class="col-md-12">
                            <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label">Profile Image</label>
                            <div class="col-sm-10">
                                <input type="file" class="form-control"  id="classes" placeholder="">
                            </div>
                        </div>
                    </div>
                </div> -->
          </CForm>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <CButton color="primary" @click="save_data">{{
                labels.submit
              }}</CButton>
              <CButton class="ml-2" color="danger" @click="onReset">{{
                labels.reset
              }}</CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import apis from "../../apis/index";
import $ from "jquery";
import Swal from "sweetalert2";
//import Swal from 'sweetalert2/dist/sweetalert2.js';
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "studentRegister",
  data() {
    return {
      content_data: [],
      isLoading: false,
      show: false,
      showForm: true,
      fullPage: true,
      file_attachment: "",
      role: "",
      user_role_id: "",
      student_name: "",
      teacher_name: "",
      academic_items: [],
      class_items: [
        { class_id: 9, class_standard: 9 },
        { class_id: 10, class_standard: 10 },
        { class_id: 11, class_standard: 11 },
        { class_id: 12, class_standard: 12 },
      ],
      districts: [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Beed",
        "Bhandara",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai Suburban",
        "Nagpur",
        "Nanded",
        "Nandurbar",
        "Nashik",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sangli",
        "Satara",
        "Sindhudurg",
        "Solapur",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal",
      ],
      subject_items: [],
      division_items: [],
      school_list: [],
      form: {
        school: "",
        title_upload: "",
        userName: "",
        password: "",
        student_name: "",
        parent_contact: "",
        parent_email: "",
        address: "",
        city: "",
        pincode: "",
        dob: "",
        gender: "",
        stu_contact: "",
        stu_email: "",
        adhar_no: "",
        checked: [],
        division: "",
        class: "",
        registration_date: new Date(),
      },
      foods: ["Remember Me"],
      labelsEnglish: {
        school: "School Name",
        title_upload: "Title",
        title: "Upload Documents",
        subject: "Subject ",
        class: "Class",
        division: "Division",
        student: "Student Name",
        description: "Description",
        parent_name: "Parent Name",
        parent_email: "Parent Email",
        parent_contact: "Parent Contact",
        address: "Address",
        city: "City",
        pincode: "Pincode",
        dob: "DOB",
        gender: "Gender",
        adhar: "Adhar Card No.",
        stu_contact: "Contact No.",
        stu_email: "Email-Id",
        submit: "Submit",
        reset: "Reset",
        registration_date: "Registration Date",
      },
      labels: {
        school: "School Name (शाळेचे नाव)",
        school_address: "School Address	(शाळेचा पत्ता)",
        title: "Upload Study Material",
        title_upload: "Title",
        subject: "Subject",
        Year: "Academic Year",
        class: "Class (इयत्ता)",
        division: "Division (तुकडी)",
        student: "Student Name (विद्यार्थ्याचे नाव)*",
        parent_name: "Parent Name (पालकांचे नाव)",
        parent_email: "Parent Email (पालकांचा ई-मेल)",
        parent_contact: "Parent Contact (पालकांचा भ्रमरध्वनी क्रमांक)",
        address: "Address (संपूर्ण पत्ता)",
        city: "District (जिल्हा)",
        pincode: "Pincode (पिन कोड)",
        dob: "DOB (जन्म दिनांक)",
        gender: "Gender (लिंग)",
        adhar: "Adhar Card No. (आधार कार्ड क्रमांक)",
        stu_contact: "Contact No. (विद्यार्थ्याचा भ्रमरध्वनी क्रमांक)",
        stu_email: "Email-Id (विद्यार्थ्याचा ई-मेल)",
        description: "Description",
        submit: "Submit",
        reset: "Reset",
        registration_date: "Registration Date",
      },
      labelsMarathi: {
        school: "School Name",
        title_upload: "शीर्षक",
        title: "महा-विद्या मध्ये आपले स्वागत आहे",
        subject: "विषय",
        Year: "Academic Year",
        class: "वर्ग",
        file: "फाईल",
        student: "Student Name",
        parent_name: "Parent Name",
        parent_email: "Parent Email",
        parent_contact: "Parent Contact",
        address: "Address",
        city: "City",
        pincode: "Pincode",
        dob: "DOB",
        division: "Division",
        gender: "Gender",
        adhar: "Adhar Card No.",
        stu_contact: "Contact No.",
        stu_email: "Email-Id",
        description: "वर्णन",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित",
        registration_date: "Registration Date",
      },
    };
  },
  components: {},
  methods: {
    get_academic_year() {
      var promise = apis
        .list_AcademicYears()
        .then((response) => {
          this.academic_items = response.data;
        })
        .catch((error) => {});
    },
    get_class() {
      var promise = apis
        .list_ClassMasters()
        .then((response) => {
          this.class_items = response.data;
        })
        .catch((error) => {});
    },
    get_division() {
      var promise = apis
        .list_DivisionMasters()
        .then((response) => {
          this.division_items = response.data;
        })
        .catch((error) => {});
    },
    get_teacher() {
      var promise = apis
        .list_Teachers()
        .then((response) => {
          this.teacher_items = response.data;
        })
        .catch((error) => {});
    },
    get_subject() {
      var promise = apis
        .list_Subjects()
        .then((response) => {
          this.subject_items = response.data;
        })
        .catch((error) => {});
    },
    get_division() {
      var promise = apis
        .list_DivisionMasters()
        .then((response) => {
          this.division_items = response.data;
        })
        .catch((error) => {});
    },
    click_me() {
      this.isLoading = true;
      var data = {
        student_id: this.form.student,
      };
      var promise = apis
        .studentbyid(data)
        .then((response) => {
          this.isLoading = false;
          this.student_name = response.data[0].student_name;
        })
        .catch((error) => {});
    },
    click_me2() {
      this.isLoading = true;
      var data = {
        teacher_id: this.form.teacher,
      };
      var promise = apis
        .teacherbyid(data)
        .then((response) => {
          this.isLoading = false;
          this.teacher_name = response.data[0].teacher_name;
        })
        .catch((error) => {});
    },
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];
    },
    submit_data() {
      alert("here");
    },
    save_data() {
      if (this.form.student_name == "" || this.form.student_name == undefined) {
        $("#name_err")
          .css("color", "red")
          .text("Student Name Required *")
          .show();
        setTimeout(function () {
          $("#name_err").hide();
        }, 2000);
        return;
      }
      if (this.form.adhar_no == "" || this.form.adhar_no == undefined) {
        $("#adhar_err")
          .css("color", "red")
          .text("Adhar No. Required * ")
          .show();
        setTimeout(function () {
          $("#adhar_err").hide();
        }, 2000);
        return;
      }
      if (this.form.dob == "" || this.form.dob == undefined) {
        $("#dob_err").css("color", "red").text("DOB Field Required * ").show();
        setTimeout(function () {
          $("#dob_err").hide();
        }, 2000);
        return;
      }
      if (this.form.gender == "" || this.form.gender == undefined) {
        $("gender_err").css("color", "red").text(" Gender Required * ").show();
        setTimeout(function () {
          $("#gender_err").hide();
        }, 2000);
        return;
      }
      if (this.form.class == "" || this.form.class == undefined) {
        $("class_err")
          .css("color", "red")
          .text("Please Select Class * ")
          .show();
        setTimeout(function () {
          $("#class_err").hide();
        }, 2000);
        return;
      }
      if (this.form.division == "" || this.form.division == undefined) {
        $("division_err")
          .css("color", "red")
          .text("Please Select Division * ")
          .show();
        setTimeout(function () {
          $("#division_err").hide();
        }, 2000);
        return;
      }
      if (
        this.form.registration_date == "" ||
        this.form.registration_date == undefined
      ) {
        $("date_err")
          .css("color", "red")
          .text("Registration Date Required *")
          .show();
        setTimeout(function () {
          $("#date_err").hide();
        }, 2000);
        return;
      }
      if (this.form.parent_name == "" || this.form.parent_name == undefined) {
        $("parent_name_err")
          .css("color", "red")
          .text("Parent Name Required *")
          .show();
        setTimeout(function () {
          $("#parent_name_err").hide();
        }, 2000);
        return;
      }
      if (
        this.form.parent_contact == "" ||
        this.form.parent_contact == undefined
      ) {
        $("parent_contact_err")
          .css("color", "red")
          .text("Contact No. Required *")
          .show();
        setTimeout(function () {
          $("#parent_contact_err").hide();
        }, 2000);
        return;
      }
      if (this.form.parent_email == "" || this.form.parent_email == undefined) {
        $("parent_email_err")
          .css("color", "red")
          .text("Email-id Required *")
          .show();
        setTimeout(function () {
          $("#parent_email_err").hide();
        }, 2000);
        return;
      }
      if (this.form.school == "" || this.form.school == undefined) {
        $("school_err")
          .css("color", "red")
          .text("School Name Required *")
          .show();
        setTimeout(function () {
          $("#school_err").hide();
        }, 2000);
        return;
      }
      this.show = true;
      //return;
      var data = {
        student_name: this.form.student_name,
        student_address: this.form.address,
        student_dob: this.form.dob,
        school_id: this.form.school,
        parent_name: this.form.parent_name,
        parent_email_id: this.form.parent_email,
        parent_contact_number: this.form.parent_contact,
        city: this.form.city,
        pincode: this.form.pincode,
        email: this.form.stu_email,
        contact_no: this.form.stu_contact,
        class: this.form.class,
        division: this.form.division,
        image: "",
      };
      console.log(data);
      var promise = apis
        .add_Students(data)
        .then((response) => {
          this.show = false;
          Swal.fire({
            width: 300,
            title: "Registered Successfully !!",
            Text: "Registered Successfully !!",
          });
          this.onReset();
        })
        .catch((error) => {
          this.show = false;
          Swal.fire({
            width: 300,
            title: "Something Went Wrong !!",
            Text: "Something Went Wrong !!",
          });
          //Swal.fire('Something Went Wrong !!');
        });
    },
    get_school() {
      var promise = apis
        .list_Schools()
        .then((response) => {
          this.school_list = response.data;
        })
        .catch((error) => {});
    },
    onReset() {
      this.form.student_name = "";
      this.form.stu_contact = "";
      this.form.stu_email = "";
      this.form.adhar_no = "";
      this.form.dob = "";
      this.form.gender = "";
      this.form.school = "";
      this.form.class = "";
      this.form.division = "";
      this.form.parent_name = "";
      this.form.parent_contact = "";
      this.form.parent_email = "";
      this.form.address = "";
      this.form.city = "";
      this.form.pincode = "";
      this.form.registration_date = "";
      // this.form.checked = [];
      // this.show = false;
      // this.$nextTick(() => {
      //     this.show = true;
      // });
    },
  },
  created() {
    // this.get_academic_year();
    //this.get_class();
    //this.get_division();
    // this.get_class();
    // this.get_subject();
    //this.get_school();
    // let user_id = this.$session.get("user_id");
    // if (user_id == undefined || user_id == null) {
    //   return "/";
    // }
    let student = JSON.parse(localStorage.getItem("student"));
    if (student) {
      this.showForm = false;
      this.form = student;
    }
  },
};
</script>
